import styled from 'styled-components';
import { variant } from 'styled-system';
// import { colorStyle } from 'common/source/styles/theme/customVariant';
// import { base } from 'common/source/styles/theme/base';

const AlertStyle = styled.div`
  /* Alert default style */
  padding: 8px 5px;
  border-radius: 4px;
  ${'' /* border-width: 1px;
  border-style: solid; */}
  border-color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.danger};
  &.success {
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Material style goes here */
  &.is-material {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  }

  /* Style system custom color variant */
  ${'' /* ${colorStyle} */}
  ${'' /* ${base} */}
`;

// prop types can also be added from the style functions
AlertStyle.propTypes = {
  ...variant.propTypes
};

AlertStyle.displayName = 'AlertStyle';

export default AlertStyle;
