import React from 'react';
import Banner from 'common/source/containers/Banner';
import Container from 'common/source/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import { Icon } from 'react-icons-kit';
import { phoneIncoming } from 'react-icons-kit/feather/phoneIncoming';
import { mail } from 'react-icons-kit/feather/mail';
import ContactViewWrapper from './contactView.style';

import ContactForm from './ContactForm';

class ContactView extends React.Component {
  render() {
    const { location } = this.props;
    const searchParam = location.search || '';
    const context = searchParam.replace('?product=', '');
    return (
      <ContactViewWrapper>
        <Banner
          heading="Contacto"
          subtitle="Cualquier consulta sobre nuestros productos y servicios, no dudes en contactarnos mediante cualquier metodo. Puedes comunicarte por mail en cualquier momento, o puedes llamar a nuestras oficinas de Lunes a Viernes de 9:00 a 17:00hrs."
          background="blue"
        >
          <Box className="row">
            <Box className="col">
              <h3>TELÉFONO CONTACTO</h3>
              <div className="contact_detail">
                <span>
                  <Icon icon={phoneIncoming} size={32} />
                </span>
                <p>+56 (2) 3267 8448</p>
              </div>
            </Box>
            <Box className="col">
              <h3>EMAIL CONTACTO</h3>
              <div className="contact_detail">
                <span>
                  <Icon icon={mail} size={32} />
                </span>
                <p>contacto@upsolar.cl</p>
              </div>
            </Box>
          </Box>
        </Banner>
        <Container>
          <Box pt="50px" pb="50px">
            <h2 style={{ paddingLeft: 30 }}>Escríbenos!</h2>
            <ContactForm context={context} />
          </Box>
        </Container>
      </ContactViewWrapper>
    );
  }
}

export default ContactView;
