import axios from 'axios';

export const validateFunc = values => {
  const errors = {};
  if (!values.name) {
    errors.name = '*Ingrese su nombre!';
  }
  if (!values.lastname) {
    errors.lastname = '*Por favor, Ingrese su apellido';
  }
  if (!values.email) {
    errors.email = '*Ingrese su dirección de correo';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = '*Dirección de correo no es válida';
  }
  if (values.message.length < 5) {
    errors.message = '*Escríbenos un mensaje!';
  }

  return errors;
};

export function postData(data) {
  const POST_API = 'https://yx8vpnhtdh.execute-api.us-west-2.amazonaws.com/prod/submit';
  const config = { SHEET_ID: '1fNyp8_sJVbtH0kvKNDE57wsKkugdfnHEZvmw9gtm6po' };
  return axios.post(`${POST_API}?form=contact`, { ...data, ...config });
}
